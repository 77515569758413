export enum TemplateCreation {
  CORIGHT = 'CORIGHT',
  CUSTOM = 'CUSTOM',
}

export type DeliveryType =
  | 'DEFAULT'
  | 'USAGE_RIGHTS'
  | 'APPROVED_OUTSIDE'
  | 'PDF';

export type Template = {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdById: string;
  updatedById: string;
  creation: TemplateCreation;
  name: string;
  description: string;
  sections: string[];
  createdByFullName: string;
  updatedByFullName: string;
  nameNo: string;
  descriptionNo: string;
  iconUrl: string;
  deliveryType: DeliveryType;
};
